<template>
<div>
	<div class="ud-hero mt-10"></div>
	<div class="card">
	  <div class="container mt-3">
	    <h3>Refund Policy</h3>
	    <p class="ud-feature-desc">Last Updated 10 Oct 2024</p>
	    <br/>
	   <p>Thank you for choosing our services. Please review our refund policy below:</p>
	   </br></br>
       <h4>Refunds:</h4>
        <br/>
        <p>
            We do not provide refunds except under the following condition:
            <ul style="list-style-type:disc" class="ms-4">
                <li>If you subscribe to our service and we are unable to provide it due to circumstance beyond our control.</li>
                <li>If we are unable to deliver the services as agreed, you may request a refund. To be eligible, please contact us within 7 days of the service failure.</li>
            </ul>
        </p>
        <br/><br/>
       <h4>Refund Process:</h4>
        <br/>
        <p>
             Once your refund request is approved, we will initiate the refund. Refunds will be credited to your original payment method within 5-7 working days after the refund is initiated.
        </p>
        <br/><br/>
        <h4>Important Note:</h4>
        <br/>
        <p>
            Once you subscribe for a specific period and cancel your subscription, you will not be eligible
            for a refund. However, you can continue to use the service until your subscription end date.
        </p>
        <br/><br/>
        <h4>Contact Information:</h4>
        <br/>
        <p>
            If you have any question or need further assistance, please contact our support team.</br>
            <b>Email:</b> <a href="mailto:support@softpital.in"> support@softpital.in</a></br>
            <b>Mobile Number:</b> +91 7383543849</br>
            <b>Address:</b> Ahmedabad, Gujarat - India</br>
        </p>
        <br/><br/>
  </div>
</div>
</div>
</template>

<script>

export default {
  name: 'Features',
   components: {

  },
  
  methods : {
	
  }
}
</script>
<style>
</style>
